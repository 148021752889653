import { BehaviorSubject } from 'rxjs';

import jsonStorage from '../utils/json-storage';

const subjectTakeAwayShop = new BehaviorSubject([]);

export const TakeAwayShops = {
  update: takeAwayshops => {
    subjectTakeAwayShop.next(takeAwayshops);
  },
  subscribe: setState => subjectTakeAwayShop.subscribe(setState),
  getValue: () => subjectTakeAwayShop.value,
};

export const Shop = {
  subject: undefined,

  lazyInit() {
    if (Shop.subject) return Shop.subject;

    if (window?.localStorage) {
      const storage = window.localStorage;
      const shop = jsonStorage.get('Shop', { storage });

      Shop.subject = new BehaviorSubject(shop);
      return Shop.subject;
    }
  },

  update(_shop) {
    const shop = Shop.lazyInit();
    shop.next(_shop);
    const storage = window.localStorage;
    jsonStorage.set('Shop', _shop, { storage });
  },

  subscribe(setState) {
    const shop = Shop.lazyInit();
    return shop.subscribe(setState);
  },

  getValue() {
    const shop = Shop.lazyInit();
    return shop.value;
  },

  setEasyShop(easyShop) {
    const shop = Shop.getValue();
    Shop.update({
      ...shop,
      easyShop,
    });
  },

  setExpressShop(expressShop) {
    const shop = Shop.getValue();
    Shop.update({
      ...shop,
      expressShop,
    });
  },

  setTakeAwayShop(takeAwayShop) {
    const shop = Shop.getValue();
    Shop.update({
      ...shop,
      takeAwayShop,
    });
  },

  getStorageValue: () => {
    const storage = window.localStorage;
    return jsonStorage.get('Shop', { storage });
  },
};

export default { Shop, TakeAwayShops };
