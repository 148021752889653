import { BehaviorSubject } from 'rxjs';

const subjectLimitations = new BehaviorSubject([]);

const OrderLimitationsStore = {
  update: orderLimitations => {
    subjectLimitations.next(orderLimitations);
  },
  subscribe: setState => subjectLimitations.subscribe(setState),
  getValue: () => subjectLimitations.value,
  subject: subjectLimitations,
};

export default OrderLimitationsStore;
