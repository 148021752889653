import { wave } from './config';

export default async function getCampaigns(notCached) {
  try {
    const now = notCached ? Date.now() : undefined;
    const response = await wave.get(`/v2/discountCampaigns`, {
      headers: {
        appId: process.env.appId,
      },
      params: {
        now,
      },
    });
    const campaigns = response?.data?.payload?.campaigns || [];
    return { campaigns };
  } catch (error) {
    return { campaigns: [], error };
  }
}
