import { BehaviorSubject } from 'rxjs';
import Cookies from 'universal-cookie';

import jsonStorage from '../utils/json-storage';

const cookie = new Cookies();

const Cookie = {
  subject: null,

  lazyInit() {
    if (Cookie.subject) return Cookie.subject;

    const storage = window.localStorage;
    const cookies = jsonStorage.get(`${process.env.appName}:privacy`, { storage });

    Cookie.subject = new BehaviorSubject(cookies);
    return Cookie.subject;
  },

  update(_cookies) {
    const cookieSubject = Cookie.lazyInit();
    cookieSubject.next(_cookies);
    if (!_cookies.ad) Cookie.deleteAd(_cookies);
    if (!_cookies.performance) Cookie.deletePerformance(_cookies);
    const storage = window.localStorage;
    jsonStorage.set(`${process.env.appName}:privacy`, _cookies, { storage });
  },
  deleteAd() {
    [
      'cp_sessionTime',
      'cpab',
      'cp_shownnotscookie',
      'cp_total_cart_value',
      'cp_total_cart_items',
      'bi',
      'IDE',
      '_gcl_au',
      '_fbp',
    ].forEach(c => cookie.remove(c, { path: '/' }));
  },
  deletePerformance() {
    ['_gid', '_gat_UA-105613924-4', '_ga', 'NID', 'NID', 'CONSENT', 'CONSENT', '1P_JAR', 'DV'].forEach(c => cookie.remove(c, { path: '/' })
    );
  },

  subscribe(setState) {
    const cookies = Cookie.lazyInit();
    return cookies.subscribe(setState);
  },

  getValue() {
    const cookies = Cookie.lazyInit();
    return cookies.value;
  },
};

export default Cookie;
