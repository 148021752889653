const BANNER_SORT_OPTIONS = {
  catalog: 'default',
  offer: 'offer',
  alphabetical: 'alphabetical',
  expensive: 'highFirst',
  cheap: 'lowFirst',
  kiloPrice: 'kiloPrice',
};

export default BANNER_SORT_OPTIONS;
