import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import jsonStorage from '../utils/json-storage';

const subjectProductsRemovedFromCart = new BehaviorSubject([]);
const subjectProductsNotRemovedFromCart = new BehaviorSubject([]);

const subjectCartVolume = new BehaviorSubject(0);
const subjectShowCartFullMsg = new BehaviorSubject(false);
const subjectCartPrice = new BehaviorSubject(0);
const subjectCreateFromCart = new BehaviorSubject(false);

const subjectValuesToBeUpdated = new BehaviorSubject({});

const INITIAL_VALUE = Object.freeze({
  isVisible: false,
  isEditable: true,
  shouldOpen: true,
  createFormCart: false,
});

export const createFromCart = {
  subject: new BehaviorSubject(INITIAL_VALUE),

  update(value) {
    subjectCreateFromCart.next(value);
  },
  subscribe: setState => subjectCreateFromCart.subscribe(setState),
  getValue: () => subjectCreateFromCart.value,
};

const Cart = {
  subject: new BehaviorSubject(INITIAL_VALUE),
  updatedAt: undefined,

  update(value) {
    Cart.subject.next(value);
    Cart.updatedAt = new Date();
  },

  deltaUpdate(updateObject) {
    const currentState = Cart.subject.value;
    Cart.subject.next({
      ...currentState,
      ...updateObject,
    });
  },

  /**
   * Updates cart according to visiting url.
   * @param {String} relativeUrl - pathname & query params
   */
  onRouteChangeComplete(relativeUrl) {
    const isCheckoutPage = ['/order-delivery'].some(u => relativeUrl.startsWith(u));
    const isFullPageCart = ['/cart'].find(u => relativeUrl.startsWith(u));
    if (isFullPageCart) {
      Cart.setIsVisible(false);
      return;
    }
    if (!isCheckoutPage) {
      Cart.setIsEditable(true);
    }
  },

  getIsVisble$() {
    return Cart.subject.pipe(map(cart => cart.isVisible));
  },

  getIsEditable$() {
    return Cart.subject.pipe(map(cart => cart.isEditable));
  },

  getIsModalOpen$() {
    return Cart.subject.pipe(map(cart => cart.isModalOpen));
  },

  setIsVisible(value) {
    Cart.deltaUpdate({ isVisible: value });
  },

  setIsEditable(value) {
    Cart.deltaUpdate({ isEditable: value });
  },

  setIsModalOpen(value) {
    Cart.deltaUpdate({ isModalOpen: value });
  },

  toggleVisibility() {
    const newState = !Cart.subject.value.isVisible;
    Cart.setIsVisible(newState);
  },

  reset() {
    productsForOrder.update([]);
    productsQuantities.update({});
    Cart.subject.next(INITIAL_VALUE);
  },
};

export const productsForOrder = {
  subject: undefined,

  lazyInit() {
    if (productsForOrder.subject) return productsForOrder.subject;
    const storage = window.localStorage;
    const products = jsonStorage.get('productForOrder', { storage }) || [];

    productsForOrder.subject = new BehaviorSubject(products);
    return productsForOrder.subject;
  },

  update: value => {
    const products = productsForOrder.lazyInit();
    products.next(value);
    const storage = window.localStorage;
    jsonStorage.set('productForOrder', value, { storage });

    const tempQuantities = productsForOrder.getValue().reduce((cartProducts, cartProduct) => {
      const newQuantity = (cartProducts[cartProduct._id] || 0) + 1;
      const cartProductsQuantities = {
        ...cartProducts,
        [cartProduct._id]: newQuantity,
      };
      return cartProductsQuantities;
    }, {});

    productsQuantities.update(tempQuantities);
  },
  getStorageValue: () => {
    const storage = window.localStorage;
    return jsonStorage.get('productForOrder', { storage });
  },
  subscribe: setState => {
    const products = productsForOrder.lazyInit();
    return products.subscribe(setState);
  },
  getValue: () => {
    const products = productsForOrder.lazyInit();
    return products.value;
  },
};

export const productsQuantities = {
  subject: undefined,

  lazyInit() {
    if (productsQuantities.subject) return productsQuantities.subject;
    const storage = window.localStorage;
    const quantities = jsonStorage.get('productQuantities', { storage }) || {};
    productsQuantities.subject = new BehaviorSubject(quantities);
    return productsQuantities.subject;
  },

  update: value => {
    const quantities = productsQuantities.lazyInit();
    quantities.next(value);
    const storage = window.localStorage;
    jsonStorage.set('productQuantities', value, { storage });
  },

  subscribe: setState => {
    const quantities = productsQuantities.lazyInit();
    return quantities.subscribe(setState);
  },
  getValue: () => {
    const quantities = productsQuantities.lazyInit();
    return quantities.value;
  },
};

export const ProductsRemovedFromCart = {
  update: value => {
    subjectProductsRemovedFromCart.next(value);
  },
  subscribe: setState => subjectProductsRemovedFromCart.subscribe(setState),
  getValue: () => subjectProductsRemovedFromCart.value,
};

export const ProductsNotRemovedFromCart = {
  update: value => {
    subjectProductsNotRemovedFromCart.next(value);
  },
  subscribe: setState => subjectProductsNotRemovedFromCart.subscribe(setState),
  getValue: () => subjectProductsNotRemovedFromCart.value,
};

export const cartVolume = {
  update: value => {
    subjectCartVolume.next(value);
  },
  subscribe: setState => subjectCartVolume.subscribe(setState),
  getValue: () => subjectCartVolume.value,
};

export const cartPrice = {
  subject: subjectCartPrice,

  update: value => {
    subjectCartPrice.next(value);
  },
  subscribe: setState => subjectCartPrice.subscribe(setState),
  getValue: () => subjectCartPrice.value,
};

export const totalCartPriceStore = {
  subject: null,
  _lazyInit() {
    if (totalCartPriceStore.subject) return totalCartPriceStore.subject;
    const storage = window.localStorage;
    const total = jsonStorage.get('totalCartPrice', { storage }) || {};
    totalCartPriceStore.subject = new BehaviorSubject(total);
    return totalCartPriceStore.subject;
  },
  update: totalCartPriceValue => {
    const totalCartPrice = totalCartPriceStore._lazyInit();
    totalCartPrice.next(totalCartPriceValue);
    const storage = window.localStorage;
    jsonStorage.set('totalCartPrice', totalCartPriceValue, { storage });
  },
  subscribe: setState => {
    const totalCartPrice = totalCartPriceStore._lazyInit();
    return totalCartPrice.subscribe(setState);
  },
  getValue: () => {
    const totalCartPrice = totalCartPriceStore._lazyInit();
    return totalCartPrice.value;
  },
  clear: () => {
    window.localStorage.removeItem('totalCartPrice');
  },
};

export const showCartFullMsg = {
  update: value => {
    subjectShowCartFullMsg.next(value);
  },
  subscribe: setState => subjectShowCartFullMsg.subscribe(setState),
  getValue: () => subjectShowCartFullMsg.value,
};

export const ValuesToBeUpdated = {
  updateService: value => {
    const values = ValuesToBeUpdated.getValue();
    subjectValuesToBeUpdated.next({ ...values, service: value });
  },
  updateAddress: value => {
    const values = ValuesToBeUpdated.getValue();
    subjectValuesToBeUpdated.next({ ...values, address: value });
  },
  updateShop: value => {
    const values = ValuesToBeUpdated.getValue();
    subjectValuesToBeUpdated.next({ ...values, shop: value });
  },
  updatePostalCode: value => {
    const values = ValuesToBeUpdated.getValue();
    subjectValuesToBeUpdated.next({ ...values, postalCode: value });
  },
  subscribe: setState => subjectValuesToBeUpdated.subscribe(setState),
  getValue: () => subjectValuesToBeUpdated.value,
  clearValues: () => {
    subjectValuesToBeUpdated.next({});
  },
};

export default Cart;
