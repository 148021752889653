import get from 'lodash/get';

import { parseError } from '../utils/api';

import { wave } from './config';

const UNDEFINED_ERROR_MESSAGE = 'Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε ξανά.';

export async function getByPostalCode(postalCode, orderShippingType = 'delivery') {
  const encodedPostalCode = btoa(postalCode);
  try {
    const response = await wave.get('/shops/findShopsByPostalCode', {
      params: {
        appId: process.env.appId,
        postalCode: encodedPostalCode,
        orderShippingType,
      },
    });
    return response.data.payload.shops;
  } catch (error) {
    if (!error?.response?.data?.error?.status || error.response.data.error.status === 404) {
      return 404;
    }
    return [];
  }
}

export async function getTimeslots(postalCode) {
  try {
    const response = await wave.get('/v2/timeslots/available', {
      params: {
        postalCode,
        service: 'express',
      },
    });

    const timeslots = get(response, 'data.payload.timeslots');
    const extraInfo = get(response, 'data.extraInfo');
    return {
      timeslots,
      extraInfo,
    };
  } catch (error) {
    return error?.data?.error || error?.error || UNDEFINED_ERROR_MESSAGE;
  }
}

export async function getTimeslotsForTakeAway(postalCode) {
  try {
    const response = await wave.get(`/v2/timeslots/available`, {
      params: {
        postalCode,
        service: 'takeAway',
      },
    });
    const extraInfo = get(response, 'data.payload.extraInfo');
    const timeslots = get(response, 'data.payload.timeslots');

    return {
      timeslots,
      extraInfo,
    };
  } catch (err) {
    const error = parseError(err);
    return { error };
  }
}

export async function getTimeslotsForTakeAwayShop(shopId) {
  try {
    const response = await wave.get(`/v2/timeslots/available`, {
      params: {
        shopId,
        service: 'takeAway',
      },
    });
    const timeslots = get(response, 'data.payload.timeslots');
    const extraInfo = get(response, 'data.payload.extraInfo');

    return {
      timeslots,
      extraInfo,
    };
  } catch (err) {
    const error = parseError(err);
    return { error };
  }
}

export async function getShops(services = [], postalCode = null, available = true) {
  try {
    const response = await wave.get('/v2/shops', {
      headers: {
        appId: process.env.appId,
      },
      params: {
        deliversToPostalCodes_eq: postalCode,
        available_eq: available,
        services_in: services,
      },
    });
    const shops = response?.data?.payload.shops;
    return { shops };
  } catch (err) {
    const error = parseError(err);
    return { shops: [], error };
  }
}

export async function getShopsForGuestUser(isGuest, services = []) {
  try {
    const response = await wave.get('/v2/shops', {
      headers: {
        appId: process.env.appId,
      },
      params: {
        guestShop_eq: isGuest,
        services_in: services,
      },
    });
    const shops = response?.data?.payload.shops;
    return { shops };
  } catch (err) {
    const error = parseError(err);
    return { shops: [], error };
  }
}
