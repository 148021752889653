import { wave } from './config';

export async function getCategories(shopId) {
  const response = await wave.get('/v2/categories/tree', {
    params: {
      shopId_eq: shopId,
    },
  });
  return response.data.payload.categories;
}

export async function getCategoriesCollectionType(collectionType) {
  const response = await wave.get('/v2/categories/tree', {
    params: {
      collectionType,
      countProduct: 'true',
    },
  });
  return response.data.payload.categories;
}
