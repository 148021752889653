import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map, pairwise, startWith } from 'rxjs/operators';

import { getShops, getShopsForGuestUser } from '../api/Shops';
import DeliveryModeStore from '../store/deliveryMode';
import { PostalCode } from '../store/isAddressRegistered';
import { IsNetworkModal } from '../store/networkErrorModal';
import ProductsCollection from '../store/productsCollection';
import { Shop } from '../store/shop';

import { updateCategories } from './getCategories';

async function getShopFromPostalCodeAndService(_postalCode, _service) {
  if (!_postalCode) {
    const { shops: defaultShop } = await getShopsForGuestUser(true, [_service]);
    const [_defaultShop] = defaultShop;
    return _defaultShop;
  }
  const { shops: shop } = await getShops([_service], _postalCode);
  const [_shop] = shop;
  if (!_shop) {
    const { shops: defaultShop } = await getShopsForGuestUser(true, [_service]);
    const [_defaultShop] = defaultShop;
    if (!_defaultShop) IsNetworkModal.update(true);
    return _defaultShop;
  }
  return _shop;
}

export function initPostalCodeShop() {
  PostalCode.lazyInit();
  DeliveryModeStore.lazyInit();
  Shop.lazyInit();
}

export function initPostalCodeServiceShopRelationship() {
  return combineLatest([PostalCode.subject, DeliveryModeStore.subject, Shop.subject])
    .pipe(
      startWith([]),
      pairwise(),
      map(([prevValues, currentValues]) => ({ prevValues, currentValues })),
      distinctUntilChanged((prev, curr) => isEqual(prev.currentValues, curr.currentValues))
    )
    .subscribe(async postalCodeAndServiceAndShop => {
      const { prevValues, currentValues } = postalCodeAndServiceAndShop;
      const [postalCode, service, shop] = currentValues;
      const [, prevService, prevShop] = prevValues;
      let selectedShop;
      if (service !== 'takeAway') {
        selectedShop = await getShopFromPostalCodeAndService(postalCode, service);
        if (service === 'easy') {
          Shop.setEasyShop(selectedShop);
        }
        if (service === 'express') {
          Shop.setExpressShop(selectedShop);
        }
      }
      if (!shop) return;
      const currentShop = service === 'takeAway' ? shop?.takeAwayShop : selectedShop;
      if (!currentShop) return;

      const previousShop = prevShop?.[`${prevService}Shop`];
      const collectionTypes = DeliveryModeStore.getCollectionsFromShop(service);
      const prevCollectionTypes = DeliveryModeStore.getCollectionsFromShop(prevService, previousShop);

      ProductsCollection.onCollectionChangeFetch(service, currentShop, collectionTypes, prevCollectionTypes);
      updateCategories(currentShop?._id);
    });
}

export function initServiceShopCollectionRelationship(product, setState) {
  return combineLatest([DeliveryModeStore.subject, Shop.subject, ProductsCollection.subject])
    .pipe(startWith([]))
    .subscribe(serviceShopCollection => {
      const [mode, , collection] = serviceShopCollection;
      if (isEmpty(collection)) return;
      const collectionTypes = DeliveryModeStore.getCollectionAsNumber(mode);
      const allProducts = ProductsCollection.getProductsForCollections(collectionTypes);
      if (allProducts?.length === 0) return;
      const productSelected = allProducts.find(_product => _product.sku === product.sku);
      const isProductAvailable = productSelected ? productSelected.available : false;
      setState(isProductAvailable);
    });
}
