import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';

function Order(categories) {
  if (!categories) {
    return [];
  }
  const allCategories = categories.map(category => {
    const categoryTreeIndices = category.divisionCode ? category.divisionCode.split('  ') : [];
    if (!category.numberOfProductsToDisplay || categoryTreeIndices.length !== 1) {
      return;
    }
    const clonedCategory = cloneDeep(category);
    clonedCategory.subCategories = orderBy(clonedCategory.subCategories, 'categoryIndex', 'asc').filter(
      c => c.numberOfProductsToDisplay
    );
    return clonedCategory;
  });
  return allCategories;
}

export function orderCategories(categories) {
  if (!categories?.length) return;
  return Order(categories).filter(cell => cell !== undefined);
}
