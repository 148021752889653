import { captureException } from '@sentry/node';

export function initGlobalValues() {
  Object.assign(window, {
    cpaAccount: 'kritikoseasy',
    cpaE: '',
    cpa_async: {
      ctag: '',
      utmipn: '',
      utmipc: '',
      utmtid: '',
      utmtto: '',
      cutmcn: '',
      cutmcc: '',
    },
  });
}

export function updateUser(userEmail) {
  try {
    window.cpaE = userEmail;
    if (window.cpaEv) {
      window.cpaEv();
    }
    return {};
  } catch (error) {
    captureException(error);
    console.error(error);
    return { error: "Couldn't update contact pigeon" };
  }
}
