import { BehaviorSubject } from 'rxjs';

import jsonStorage from '../utils/json-storage';

const meta = {
  cached: false,
  fetched: false,
};

const subjectOpenModal = new BehaviorSubject(false);
const subjectSelectionMade = new BehaviorSubject(false);
const subjectOffers = new BehaviorSubject([]);
const subjectOffersFetched = new BehaviorSubject(false);

export const OffersFetched = {
  update: fetched => {
    subjectOffersFetched.next(fetched);
  },
};

export const OffersStore = {
  update: categories => {
    subjectOffers.next(categories);
    OffersFetched.update(true);
  },
  subscribe: setState => subjectOffers.subscribe(setState),
  getValue: () => subjectOffers.value,
  isFetched: () => subjectOffersFetched.value,
};

export const OfferSelected = {
  subject: undefined,

  lazyInit() {
    if (OfferSelected.subject) return OfferSelected.subject;
    const storage = window.localStorage;
    const offerSelected = jsonStorage.get('offerSelected', { storage });
    Object.assign(meta, {
      cached: true,
      fetched: false,
    });
    OfferSelected.subject = new BehaviorSubject(offerSelected);
    return OfferSelected.subject;
  },

  update: offer => {
    const offerSelected = OfferSelected.lazyInit();
    offerSelected.next(offer);
    Object.assign(meta, {
      fetched: true,
    });
    const storage = window.localStorage;
    jsonStorage.set('offerSelected', offer, { storage });
  },
  subscribe: setState => {
    const offerSelected = OfferSelected.lazyInit();
    return offerSelected.subscribe(setState);
  },
  getValue: () => {
    const offerSelected = OfferSelected.lazyInit();
    return offerSelected.value;
  },
};

export const SelectionMade = {
  update: selectionMade => {
    subjectSelectionMade.next(selectionMade);
  },
  subscribe: setState => subjectSelectionMade.subscribe(setState),
  getValue: () => subjectSelectionMade.value,
};

export const OfferModal = {
  update: modal => {
    subjectOpenModal.next(modal);
  },
  subscribe: setState => subjectOpenModal.subscribe(setState),
  getValue: () => subjectOpenModal.value,
};

export default {
  OffersStore,
  OffersFetched,
  OfferSelected,
  OfferModal,
  SelectionMade,
};
