import { BehaviorSubject } from 'rxjs';

import jsonStorage from '../utils/json-storage';

export const HasShownMaxQuantityStore = {
  subject: null,

  _lazyInit() {
    if (HasShownMaxQuantityStore.subject) return HasShownMaxQuantityStore.subject;
    const storage = window.sessionStorage;
    const maxQuantity = jsonStorage.get('maxQuantity', { storage });
    HasShownMaxQuantityStore.subject = new BehaviorSubject(maxQuantity);
    return HasShownMaxQuantityStore.subject;
  },
  update: maxedItemValue => {
    const maxedItemValueNext = {
      ...HasShownMaxQuantityStore.getValue(),
      ...maxedItemValue,
    };
    const maxQuantity = HasShownMaxQuantityStore._lazyInit();
    maxQuantity.next(maxedItemValueNext);

    const storage = window.sessionStorage;
    jsonStorage.set('maxQuantity', maxedItemValueNext, { storage });
  },
  subscribe: setState => {
    const maxQuantity = HasShownMaxQuantityStore._lazyInit();
    return maxQuantity.subscribe(setState);
  },
  getValue: () => {
    const maxQuantity = HasShownMaxQuantityStore._lazyInit();
    return maxQuantity.value;
  },
  clear: () => {
    window.sessionStorage.removeItem('maxQuantity');
  },
};

export default { HasShownMaxQuantityStore };
