import * as Sentry from '@sentry/react';

const enabled = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';
const allowUrls =
  process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'production' ? ['kritikos-sm.gr'] : ['kritikos-sm-stage.web.app'];
const sentryRelease = process.env.NEXT_PUBLIC_SENTRY_RELEASE;

export function setup() {
  Sentry.init({
    allowUrls,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || '',
    enabled,
    release: sentryRelease,
    sampleRate: 0.2,
    environment: process.env.NEXT_PUBLIC_APP_ENVIRONMENT || '',
    beforeSend(event, hint) {
      const error = hint.originalException;
      const partialMatch = 'serviceWorkers';
      const singularPartialMatch = 'serviceWorkers';
      const fullMatch = 'wrsParams.serviceWorkers.navigator.serviceWorker.register(<anonymous>)';
      if (
        error &&
        error.message &&
        (error.message.includes(partialMatch) ||
          error.message.includes(singularPartialMatch) ||
          error.message.match(/serviceWorkers/i) ||
          error.message.includes(fullMatch))
      ) {
        return null;
      }
      if (
        event.message &&
        (event.message.includes(partialMatch) ||
          event.message.includes(singularPartialMatch) ||
          event.message.match(/serviceWorkers/i) ||
          event.message.includes(fullMatch))
      ) {
        return null;
      }
      if (event.exception && event.exception.values) {
        for (let i = 0; i < event.exception.values.length; i++) {
          const exceptionValue = event.exception.values[i];
          if (
            exceptionValue.value &&
            (exceptionValue.value.includes(partialMatch) ||
              exceptionValue.value.includes(singularPartialMatch) ||
              exceptionValue.value.match(/serviceWorkers/i) ||
              exceptionValue.value.includes(fullMatch))
          ) {
            return null;
          }
        }
      }
      return event;
    },
    ignoreErrors: [
      'Error: Request aborted',
      'Error: Worker was terminated',
      /SyntaxError:.*/,
      /.*ServiceWorker.*/,
      /.*serviceWorkers.*/,
      /.*serviceWorker.*/,
      /.*ServiceWorkers.*/,
      /.*UnknownError.*/,
      /.*<unknown>*/,
      /.*Script https:\/\/kritikos-sm.gr\/sw.js load failed*/,
      /.*QuotaExceededError.*/,
      /.*NetworkError.*/,
      /.*Network Error.*/,
      /SecurityError: .*/,
      'TypeError: Cancelled',
      'TypeError: Failed to fetch',
      "ReferenceError: Can't find variable: instantSearchSDKJSBridgeClearHighlight",
      'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching',
      'Error: timeout of 0ms exceeded',
      'Error: Could not load "marker".',
      "TypeError: Cannot read properties of null (reading 'zoom')",
      'TypeError: Illegal invocation',
      'Event: Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
      'TypeError: __injectionJavaScriptObject___.captureReady is not a function',
      'TypeError: Load failed',
      'wrsParams.serviceWorkers.navigator.serviceWorker.register(<anonymous>)',
      'Error: wrsParams.serviceWorkers.navigator.serviceWorker.register(<anonymous>)',
      'Error',
    ],
  });
  return Sentry;
}
