const UNSUPPORTED_BROWSER_MESSAGE =
  'Ο browser σας δεν υποστηρίζει βασικές λειτουργίες της σελίδας. Θα χρειαστεί να κάνετε update ή να χρησιμοποιήσετε άλλον browser.';

/**
 * @returns true if browser is not supproted
 */
export function handleUnsupportedBrowsers() {
  if (!isBrowser()) return;

  if (!workerSupport() || !indexedDBSupport() || !localStorageSupport()) {
    return UNSUPPORTED_BROWSER_MESSAGE;
  }
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

function indexedDBSupport() {
  return window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
}

function localStorageSupport() {
  return window.localStorage;
}

function workerSupport() {
  return window.Worker;
}

export function isSmallDevice() {
  return window && window.innerWidth && window.innerWidth < 720;
}
