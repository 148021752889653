import axios from 'axios';
import get from 'lodash/get';

import { wave } from './config';

const GET_AVAILABLE_DEFAULT_PARAMS = {
  appID: process.env.appId,
  collectionType: 900,
};

export async function getAvailable(params) {
  try {
    const response = await wave.get('/products/get', {
      params: {
        ...GET_AVAILABLE_DEFAULT_PARAMS,
        ...params,
      },
    });

    const products = get(response, 'data.payload.products');
    const timestamp = get(response, 'data.payload.timestamp');

    if (!products) {
      // TODO: handle the error - showing a modal
      console.error(response);
      return {
        products: [],
        timestamp,
        error: { userMessage: 'Σφάλμα ανάκτησης δεδομένων' },
      };
    }

    return { products, timestamp };
  } catch (error) {
    return { products: [], error };
  }
}

export async function getProductsWithDetails() {
  try {
    const response = await wave.get('/v2/products', {
      params: {
        collection_eq: 'static-web',
      },
    });

    const products = get(response, 'data.payload.products');
    if (!products) {
      // TODO: handle the error - showing a modal
      console.error(response);
      return {
        products: [],
        error: { userMessage: 'Σφάλμα ανάκτησης δεδομένων' },
      };
    }

    return { products };
  } catch (error) {
    return { products: [], error };
  }
}

export async function getAvailableProducts(url, params) {
  try {
    const response = await axios.get(url, { params, headers: { appId: process.env.appId } });
    return { products: response?.data?.payload?.products };
  } catch (error) {
    return { products: [], error };
  }
}

export async function getProductConfigurations() {
  try {
    const response = await wave.get('/v2/products/configuration');
    const data = response.data.payload;
    return { data };
  } catch (error) {
    return { error };
  }
}

export default {
  getAvailable,
  getAvailableProducts,
  getProductConfigurations,
  getProductsWithDetails,
};
