import { BehaviorSubject } from 'rxjs';

const subjectCategoriesFetched = new BehaviorSubject(false);

const CategoriesFetched = {
  update: fetched => {
    subjectCategoriesFetched.next(fetched);
  },
};

const CategoryStore = {
  subject: new BehaviorSubject([]),
  update: categories => {
    CategoryStore.subject.next(categories);
    CategoriesFetched.update(true);
  },
  subscribe: setState => CategoryStore.subject.subscribe(setState),
  getValue: () => CategoryStore.subject.value,
  isFetched: () => subjectCategoriesFetched.value,
};

export default CategoryStore;
