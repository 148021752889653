import { getCategories, getCategoriesCollectionType } from '../api/Categories';
import CategoryStore from '../store/categories';

import { orderCategories } from './orderCategories';

export function getOrFetchCategories() {
  if (CategoryStore.isFetched()) {
    return CategoryStore.getValue();
  }

  return updateCategories(null, process.env.defaultCollectionType);
}

export async function updateCategories(shopId, collectionNumber) {
  const categories = shopId ? await getCategories(shopId) : await getCategoriesCollectionType(collectionNumber);
  const categoriesOrdered = orderCategories(categories);
  CategoryStore.update(categoriesOrdered);

  return categoriesOrdered;
}

export default { getOrFetchCategories, updateCategories };
