import { getUserLists } from '../api/wave/users';
import User from '../store/user';

/**
 * @param {User} user
 * @returns
 */
export async function fetchUserListsAndUpdateAsync({ _id, authToken, token } = {}) {
  const { data: fetchedLists = [], error } = await getUserLists(_id, authToken || token);
  if (error) return;
  User.setLists(fetchedLists);
}
