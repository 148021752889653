import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';

import { HasShownMaxQuantityStore } from '../store/maxQuantity';

import BANNER_SORT_OPTIONS from './bannerOptions';

export function getBrandToProductIdMap(products) {
  return products.reduce((prev, { brand, _id }) => {
    if (brand === null) return prev;
    const brandIds = [...(prev[brand] || []), _id];
    return { ...prev, [brand]: brandIds };
  }, {});
}

export function productsLimitReached(product, quantity, stateUpdate, limit) {
  const store = HasShownMaxQuantityStore.getValue();
  if (product?.products) {
    if (quantity >= limit) {
      if (store && !store[product._id]) {
        stateUpdate(true);
        setTimeout(() => HasShownMaxQuantityStore.update({ [product._id]: true }), 100);
        setTimeout(() => stateUpdate(false), 2800);
        return;
      }
    } else if (isNumber(limit)) {
      HasShownMaxQuantityStore.update({ [product._id]: false });
    }
    stateUpdate(false);
  }
  if (product.stepToUom * quantity >= limit) {
    if (store && !store[product.sku]) {
      stateUpdate(true);
      setTimeout(() => HasShownMaxQuantityStore.update({ [product.sku]: true }), 100);
      setTimeout(() => stateUpdate(false), 2800);
      return;
    }
  } else if (isNumber(limit)) {
    HasShownMaxQuantityStore.update({ [product.sku]: false });
  }
  stateUpdate(false);
}

export function getProductOrderLimit(orderLimitations, product) {
  if (product.uom === 'ΚΙΛ') {
    return isNumber(product.quantityLimit) ? product.quantityLimit / 1000 : orderLimitations.weighedProductBuyLimit;
  }
  return product.offerType === 'none'
    ? product?.quantityLimit || orderLimitations.productBuyLimit
    : product?.quantityLimit || orderLimitations.offerBuyLimit;
}

export function getDisplayName(product) {
  if (product.displayName) {
    return product.displayName;
  }

  if (product.brand) {
    return [product.brand, product.type].join(' ').trim();
  }
  if (product.categoryKind) {
    return [product.categoryKind, product.type].join(' ').trim();
  }
  return [product.name].join('').trim();
}

export function getDisplayDescription(product) {
  if (product.brand) {
    return [product.categoryKind, product.quantity].join(' ').trim();
  }
  return product.quantity || '';
}

export function getProductTransformed(product) {
  const displayName = getDisplayName(product);
  const displayDescription = getDisplayDescription(product);
  return { ...product, displayName, displayDescription };
}

export function offerLink(offer) {
  const sanitizedName = offer.name.replace(/[/%]/g, '');
  return `/offers/${sanitizedName}-${offer.friendlyId}`;
}

export function getBadgeText(product) {
  if (product.webSticker) {
    return product.webSticker;
  }
  if (product.offerType === 'super') {
    return 'Super τιμή';
  }
  if (product.offerType === 'percentage' && product.offerValue !== 0) {
    return `-${product.offerValue.toFixed(0)}%`;
  }
  if (product.offerType === 'amount') {
    return `-${product.offerValue.toFixed(2)} €`;
  }
  return '';
}

export function findProductCategory(product, categories) {
  const parentCategoryId = get(product, 'category.categoryTree.parent');
  const rootCategoryId = get(product, 'category.categoryTree.root');

  if (!rootCategoryId) {
    console.warn('No root category for product', product._id);
    return [];
  }

  if (parentCategoryId) {
    const categorySlug = categories.find(c => rootCategoryId === c._id);
    const subCategorySlug = categorySlug.subCategories.find(subC => parentCategoryId === subC._id);
    const finalCategorySlug = categorySlug.slug;
    const finalSubCategorySlug = subCategorySlug ? subCategorySlug.slug : null;
    return [finalCategorySlug, finalSubCategorySlug];
  }

  const categorySlug = categories.find(c => rootCategoryId === c._id);
  const subCategorySlug = categorySlug.subCategories.find(subC => product.category._id === subC._id);
  return [categorySlug.slug, get(subCategorySlug, 'slug')];
}

export function getSortedProducts(products, sortBy) {
  switch (sortBy) {
    case BANNER_SORT_OPTIONS.offer:
      return orderBy(
        products,
        [
          productsItem => {
            return productsItem.offerType === 'super';
          },
          productsItem => {
            return productsItem.offerType !== 'none' && productsItem.offerValue;
          },
        ],
        ['desc', 'desc']
      );
    case BANNER_SORT_OPTIONS.expensive:
      return sortProducts(products, 'desc');
    case BANNER_SORT_OPTIONS.cheap:
      return sortProducts(products, 'asc');
    case BANNER_SORT_OPTIONS.alphabetical:
      return orderBy(products, 'displayName', 'asc');
    case BANNER_SORT_OPTIONS.kiloPrice:
      return orderBy(products, [
        product => {
          if (product.uom === 'TEM') {
            return Number((product.unitOfMeasurementFinalPrice / 100).toFixed(2));
          }
          return Number((product.finalPrice / 100).toFixed(2));
        },
      ]);
    default:
      return products;
  }
}

export function sortProducts(produtsArray, typeOfSort) {
  return orderBy(
    produtsArray,
    [
      product => {
        if (product.isWeighed) {
          return product.finalPrice * product.stepToUom;
        }
        return product.finalPrice;
      },
    ],
    typeOfSort
  );
}

export function getOfferSelectedProducts(offer, products) {
  if (offer) {
    if (offer.products.length !== 0 && products) {
      const productsInOffer = offer.products
        .map(currentProduct => products.filter(p => p.sku === currentProduct.sku))
        .reduce((a, b) => a.concat(b), []);
      return productsInOffer;
    }
    if (offer.groups.length !== 0 && products) {
      const productsInOffer = offer.groups.map(group => {
        const productsInGroup = group.products.map(currentProduct => products.find(p => p.sku === currentProduct.sku));
        return productsInGroup;
      });
      return productsInOffer;
    }
  }
  return null;
}

export function splitProductsIntoCategories(products, categories) {
  const categoryMap = groupBy(products, 'category.categoryTree.root');
  const categoriesId = Object.keys(categoryMap);
  const productsArray = Object.values(categoryMap).filter(p => p);
  const categoriesArray = categoriesId.map(cId => categories.find(c => c._id === cId)).filter(v => v);
  const categoriesArrayFiltered = orderBy(categoriesArray, 'categoryIndex', 'asc');
  const productsOrderSorted = sortCategoriesProducts(productsArray, categoriesArrayFiltered);
  return [productsOrderSorted, categoriesArrayFiltered];
}

export function sortCategoriesProducts(arrayToBeSorted, arrayToSortBy) {
  arrayToBeSorted.sort((a, b) => {
    const categoryIdA = a[0]?.category?.categoryTree?.root || a?.category?.categoryTree?.root;
    const categoryIdB = b[0]?.category?.categoryTree?.root || b?.category?.categoryTree?.root;
    const propertyToSortByA = arrayToSortBy.find(_category => _category._id === categoryIdA);
    const propertyToSortByB = arrayToSortBy.find(_category => _category._id === categoryIdB);
    if (propertyToSortByA?.categoryIndex > propertyToSortByB?.categoryIndex) {
      return 1;
    }
    return -1;
  });

  return arrayToBeSorted;
}

export function isOffersPage(slug) {
  return slug[0] === 'offers';
}
export function isOfferDetailsPage(slug) {
  return isOffersPage(slug) && slug.length === 2;
}
export function isOfferListPage(slug) {
  return isOffersPage(slug) && slug.length === 1;
}
export function isProductDetailsPage(slug) {
  return slug.length > 2 && !isOffersPage(slug);
}
export function isProductListPage(slug) {
  return !isOffersPage(slug) && !isProductDetailsPage(slug);
}
export function isSecondLevelPage(slug) {
  return !isOffersPage(slug) && slug.length === 2;
}
export function getSku(slug) {
  return isProductDetailsPage(slug) && last(slug);
}
export function getCategory(slug, categories) {
  return categories?.find(c => c.slugAscii === slug[0]);
}
export function getCategoryName(slug, categories) {
  const _category = getCategory(slug, categories);
  return isOffersPage(slug) ? 'Προσφορές' : _category?.name || '';
}
export function getSubCategoryName(slug, categories) {
  const _category = getCategory(slug, categories);
  if (!_category || !slug[1]) return null;

  const subCategories = get(_category, 'subCategories', []);
  const subCategory = subCategories.find(c => c.slugAscii === slug[1]);
  return subCategory?.name || '';
}
export function getPageTitle(slug, categories) {
  if (isOffersPage(slug)) {
    return 'Προσφορές';
  }
  if (!isProductListPage(slug)) return '';
  if (slug[2]) return slug[2];

  if (slug[1]) {
    return getSubCategoryName(slug, categories);
  }

  return getCategoryName(slug, categories);
}

export function productImageURL(url, position) {
  if (position === 'productTile') return `https://s3.eu-central-1.amazonaws.com/w4ve/kritikos/products/5x@${url}`;
  if (position === 'cartTile') return `https://s3.eu-central-1.amazonaws.com/w4ve/kritikos/products/3x@${url}`;
  if (position === 'productDetailsTile') return `https://s3.eu-central-1.amazonaws.com/w4ve/kritikos/products/${url}`;
}

export function getProductsGroupedByCategory(products) {
  const groupedByCategoryProducts = products.reduce((accumulator, currentValue) => {
    const sameCategoryProducts =
      accumulator?.[currentValue?.categories?.[Number(currentValue?.categories?.length) - 1]] || [];

    const groupedProducts = {
      [currentValue.categories[currentValue.categories.length - 1]]: [...sameCategoryProducts, currentValue],
    };

    return {
      ...accumulator,
      ...groupedProducts,
    };
  }, []);
  return groupedByCategoryProducts;
}

export function getConcatProductsByCategory(mainObject, secondaryObject) {
  if (isEmpty(mainObject)) {
    return secondaryObject;
  }

  const secondaryObjectKeys = Object.keys(secondaryObject);
  const secondaryObjectToArray = secondaryObjectKeys.map(value => {
    return { [value]: secondaryObject[value] };
  });

  const finalObject = secondaryObjectToArray.reduce((accumulator, currentValue) => {
    const mergedProducts = [
      ...(mainObject[Object.keys(currentValue)[0]] || []),
      ...currentValue[Object.keys(currentValue)[0]],
    ];

    const uniqueMergedProducts = [...new Set(mergedProducts.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));

    const mergedCategory = {
      [Object.keys(currentValue)[0]]: uniqueMergedProducts,
    };
    return {
      ...accumulator,
      ...mergedCategory,
    };
  }, {});

  return { ...mainObject, ...finalObject };
}
