import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import LoaderStore from '../store/loader';

import styles from './Loader.module.scss';

export default function Loader() {
  const router = useRouter();
  const [isVisible, setIsVisible] = useState();
  const [userMessage, setUserMessage] = useState('');

  useEffect(() => {
    const loader$ = LoaderStore.subscribe(state => {
      setIsVisible(state.isVisible);
      setUserMessage(state.userMessage || '');
    });

    return () => {
      loader$.unsubscribe();
    };
  }, []);

  const isHomepage = router.route === '/';
  if (isHomepage || !isVisible) return null;
  return (
    <div className={styles.container} data-cy="loader">
      <div className={styles.loader}>Loading...</div>
      <p className={styles.text}>{userMessage}</p>
    </div>
  );
}
