import { BehaviorSubject } from 'rxjs';

import { Shop } from './shop';

const INITIAL_VALUE = 'easy';
let initialized = false;

const DeliveryMode = {
  subject: undefined,
  lazyInit() {
    if (DeliveryMode.subject) return DeliveryMode.subject;
    if (!window) {
      console.error('DeliveryMode', 'lazyInit', 'called in server');
      DeliveryMode.subject = new BehaviorSubject(INITIAL_VALUE);
    } else {
      const mode = localStorage.getItem('DeliveryMode') || INITIAL_VALUE;
      DeliveryMode.subject = new BehaviorSubject(mode);
    }
    initialized = true;
    return DeliveryMode.subject;
  },
  initialized: () => initialized,
  update: mode => {
    const prevMode = DeliveryMode.getValue();
    if (mode === prevMode && mode !== 'takeAway') return;

    DeliveryMode.subject.next(mode);
    if (!window) return;

    localStorage.setItem('DeliveryMode', mode);
  },
  subscribe(setState) {
    const deliveryMode = DeliveryMode.lazyInit();
    return deliveryMode.subscribe(setState);
  },
  getValue: () => {
    const deliveryMode = DeliveryMode.lazyInit();
    return deliveryMode.value;
  },
  getCollectionsFromShop(service, shop) {
    const selectedShop = Shop.getValue();
    const serviceSelected = service || DeliveryMode.getValue();
    if (serviceSelected === 'easy') {
      const collectionTypes =
        shop?.collections?.map(collections => collections) ||
        selectedShop?.easyShop?.collections?.map(collections => collections);
      return collectionTypes;
    }
    if (serviceSelected === 'express') {
      const collectionTypes =
        shop?.collections?.map(collections => collections) ||
        selectedShop?.expressShop?.collections?.map(collections => collections);
      return collectionTypes;
    }
    if (serviceSelected === 'takeAway') {
      const collectionTypes =
        shop?.collections?.map(collections => collections) ||
        selectedShop?.takeAwayShop?.collections?.map(collections => collections);
      return collectionTypes;
    }
  },
  getCollectionAsNumber(service, shop) {
    const collectionShops = DeliveryMode.getCollectionsFromShop(service, shop);
    const collections = collectionShops?.map(_collections => _collections.collectionType);
    return collections || [process.env.defaultCollectionType];
  },
};

export default DeliveryMode;
