import axios from 'axios';

const API_URL = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api`;
const api = axios.create({
  baseURL: API_URL,
  headers: {
    appId: process.env.appId,
  },
});

export default api;
