import axios from 'axios';

const CMS_API_URL = process.env.NEXT_PUBLIC_CMS_API_URL;

const cms = axios.create({
  baseURL: CMS_API_URL,
  headers: {
    appId: 'cms-web',
  },
});

cms.interceptors.request.use(config => {
  return {
    ...config,
    params: {
      ...config.params,
      apiKey: process.env.NEXT_PUBLIC_CMS_TOKEN,
      locale: 'el-GR',
    },
  };
});

export default cms;
