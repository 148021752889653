import { BehaviorSubject } from 'rxjs';

const isNetworkModalSubject = new BehaviorSubject(false);

export const IsNetworkModal = {
  update: value => {
    isNetworkModalSubject.next(value);
  },
  subscribe: setState => isNetworkModalSubject.subscribe(setState),
  getValue: () => isNetworkModalSubject.value,
};

export default { IsNetworkModal };
