import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/el';

export function setup() {
  dayjs.locale('el');
  dayjs.extend(localizedFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  dayjs.tz.setDefault('Europe/Athens');
}

export function formatUnixDate(date, format = 'DD/MM/YYYY HH:mm') {
  return dayjs.unix(date).tz().format(format);
}

export function formatDate(date, format = 'DD/MM/YYYY HH:mm') {
  return dayjs(date).tz().format(format);
}
