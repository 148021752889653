import { captureMessage } from '@sentry/node';
import axios from 'axios';
import get from 'lodash/get';
import pick from 'lodash/pick';
import Cookies from 'universal-cookie';

import { wave } from '../config';

const cookies = new Cookies();
const auth = cookies.get('token');

const baseURL = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/orders`;
export const ORDER = axios.create({
  baseURL,
  headers: {
    appId: process.env.appId,
    authorization: `Bearer ${auth}`,
  },
  responseType: 'json',
});

export async function getOrderHistory(authorization) {
  try {
    const response = await axios.get(`${baseURL}/get/userlist`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    return get(response, 'data.payload.orders');
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getOrderLimitations() {
  try {
    const response = await axios.get(`${baseURL}/get/limitations`, {
      headers: {
        appId: process.env.appId,
      },
    });
    return response?.data?.payload?.orders;
  } catch (error) {
    return {};
  }
}

export async function createOrder(order) {
  const products =
    order.products && order.products.map(p => pick(p, ['_id', 'name', 'price', 'quantity', 'comments', 'options']));
  const body = {
    ...order,
    appVersion: 'sm',
    products,
  };

  try {
    const response = await ORDER.post('/create', body);
    const orderResponse = get(response, 'data.payload.order');
    return { order: orderResponse };
  } catch (error) {
    return { error };
  }
}

export async function getMerchantReference(merchantReference) {
  try {
    const response = await ORDER.get('/get/merchantreference', {
      params: {
        merchantReference,
      },
    });
    return get(response, 'data.payload.order');
  } catch (error) {
    const orderError = error.response ? error.response.data : 'error';
    return Promise.reject(orderError);
  }
}

export async function checkOrder(order = {}) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };

  // const products = order?.products?.map(p => pick(p, ['_id', 'name', 'finalPrice', 'quantity'])) || [];
  const products =
    order?.products?.map(p => {
      return {
        _id: p._id,
        name: p.name,
        price: p.finalPrice,
        quantity: p.quantity,
        comments: p.comments,
      };
    }) || [];
  const body = {
    ...order,
    appVersion: 'sm',
    products,
  };
  try {
    const response = await wave.post('/v2/orders/check', body, { headers });
    const orderFetched = response?.data?.payload?.order;
    if (!orderFetched) {
      const errorMessage = 'Unprocessable entity received from /v2/orders/check';
      captureMessage(errorMessage, { requestBody: body, response });
      throw Error(errorMessage);
    }

    return orderFetched;
  } catch (error) {
    return { error };
  }
}

export default { getOrderHistory, createOrder, getMerchantReference, ORDER };
