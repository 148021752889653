import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Suspense, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { distinctUntilKeyChanged, filter, map } from 'rxjs/operators';
import Cookies from 'universal-cookie';

/* CSS */
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/global.scss';
/* Stores */
import getCampaigns from '../api/Campaigns';
import { getProductConfigurations } from '../api/Products';
import { getOrderLimitations } from '../api/wave/orders';
import { getUser } from '../api/wave/users';
import Loader from '../components/Loader';
import CampaignStore from '../store/campaign';
import Cart, { productsForOrder, productsQuantities } from '../store/cart';
import ConfigsMYOStore from '../store/configsMYO';
import Cookie from '../store/cookies';
import { IsAddressRegistered } from '../store/isAddressRegistered';
import LoaderStore from '../store/loader';
import { IsNetworkModal } from '../store/networkErrorModal';
import OrderLimitationsStore from '../store/orderLimitations';
import User from '../store/user';
import { defaultConsent, updateConsent } from '../utils/analytics';
/* Utils */
import { handleUnsupportedBrowsers } from '../utils/browser';
import { clearCart } from '../utils/cart';
import { initGlobalValues as setupContactPigeon } from '../utils/contactPigeon';
import { setup as setupDayjs } from '../utils/dayjs';
import { getOrFetchCategories } from '../utils/getCategories';
import jsonStorage from '../utils/json-storage';
import { fetchUserListsAndUpdateAsync } from '../utils/list';
import { setup as setupSentry } from '../utils/sentry';
import { initPostalCodeServiceShopRelationship, initPostalCodeShop } from '../utils/storeRelationShips';

const GenericModalV2 = dynamic(() => import('../components/GenericModalV2'), {
  suspense: true,
});

setupDayjs();
const sentry = setupSentry();
const cookies = new Cookies();
const token = cookies.get('token');
const { NEXT_PUBLIC_GTM_KEY } = process.env;

function MyApp({ Component, pageProps, err }) {
  const router = useRouter();
  const [openModal, setOpenModal] = useState(false);
  const [cookiesAdvertisement, setCookiesAdvertisement] = useState(false);
  const [cookiesSupport, setCookiesSupport] = useState();
  const [hasFetchedProducts, setHasFetchedProducts] = useState(false);
  const [isAddressRegistered, setIsAddressRegistered] = useState(false);

  useEffect(() => {
    const cookies$ = Cookie.subscribe(accepted => {
      setCookiesSupport(accepted);
      if (!accepted) return;
      const { ad, performance } = accepted;
      TagManager.initialize({ gtmId: NEXT_PUBLIC_GTM_KEY });
      defaultConsent();

      if (ad) {
        setCookiesAdvertisement(accepted);
      }

      updateConsent(!!performance, !!ad);

      if (!ad) {
        Cookie.deleteAd();
        setCookiesAdvertisement(false);
      }
      if (!performance) Cookie.deletePerformance();
    });

    initPostalCodeShop();
    async function getCurrentUser() {
      const updatedUser = await getUser(token);
      User.update({
        ...updatedUser,
        authToken: token,
      });
    }

    async function getCurrentCampaigns() {
      const { campaigns, error } = await getCampaigns();
      if (!error) {
        CampaignStore.update(campaigns);
        return;
      }
      IsNetworkModal.update(true);
      CampaignStore.update([]);
    }

    async function getCurrentConfigsMYO() {
      const { data, error } = await getProductConfigurations();
      if (error) {
        ConfigsMYOStore.update([]);
        return;
      }
      ConfigsMYOStore.update(data);
    }

    const isBrowserUnsupported = handleUnsupportedBrowsers();
    if (isBrowserUnsupported) {
      // eslint-disable-next-line no-alert
      return alert(isBrowserUnsupported);
    }

    async function getCurrentOrderLimitations() {
      const orderLimitations = await getOrderLimitations();
      OrderLimitationsStore.update(orderLimitations);
    }

    setupContactPigeon();
    getCurrentCampaigns();
    getCurrentConfigsMYO();
    getCurrentOrderLimitations();
    if (token) {
      getCurrentUser();
    } else {
      const user = User.getValue();
      if (user) {
        User.reset();
        clearCart();
      }
    }

    User.getSubject()
      .pipe(
        map(v => v || {}), // distinct will not crash on undefined
        distinctUntilKeyChanged('_id'), // distinct only if there is a new _id - no user to user
        filter(u => u?._id) // prevent execution if there is no user - this must go after distinct
      )
      .subscribe(u => {
        fetchUserListsAndUpdateAsync(u);
      });

    User.updateMainAddressInstance();

    router.events.on('routeChangeStart', () => {
      LoaderStore.addEvent('ROUTE_CHANGE');
    });

    router.events.on('routeChangeComplete', relativeUrl => {
      Cart.onRouteChangeComplete(relativeUrl);
      LoaderStore.removeEvent('ROUTE_CHANGE');
    });

    router.events.on('routeChangeError', () => {
      LoaderStore.removeEvent('ROUTE_CHANGE');
    });

    getOrFetchCategories();

    const storage = window.localStorage;
    if (!jsonStorage.get('productForOrder', { storage })) {
      productsForOrder.update([]);
      productsQuantities.update({});
    }
    const isNetworkModal$ = IsNetworkModal.subscribe(setOpenModal);
    const isAddressRegistered$ = IsAddressRegistered.subscribe(setIsAddressRegistered);
    return () => {
      cookies$.unsubscribe();
      isNetworkModal$.unsubscribe();
      isAddressRegistered$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if ((router.asPath !== '/' || isAddressRegistered || token) && !hasFetchedProducts) {
      LoaderStore.addEvent('MYAPP_PRODUCTS_READY');
      initPostalCodeServiceShopRelationship();
      setHasFetchedProducts(true);
      LoaderStore.removeEvent('MYAPP_PRODUCTS_READY');
    }
  }, [isAddressRegistered, router]);

  useEffect(() => {
    if (process.env.appName === 'kritikos') {
      const zendeskScript = document.getElementById('ze-snippet');
      if (zendeskScript) return;
      const storage = window.localStorage;
      const privacy = jsonStorage.get(`${process.env.appName}:privacy`, { storage });
      if (privacy) {
        const script = document.createElement('script');
        script.async = true;
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=cd0f2ba5-4075-4820-8d28-9e10065249a3';
        document.body.appendChild(script);
        setTimeout(() => {
          if (window.zE) {
            window.zE('webWidget', 'setLocale', 'el');
          }
        }, 1500);
      }
    }
  }, [cookiesSupport]);
  return (
    <>
      <Loader />
      <Component {...pageProps} err={err} />
      {process.env.appName === 'kritikos' && cookiesAdvertisement && (
        <Script
          strategy="afterInteractive"
          src="//ping.contactpigeon.com/bi/js/cpagent142209.js?cid=kritikoseasy&v=001"
        />
      )}
      {openModal && (
        <Suspense fallback={<p />}>
          <GenericModalV2
            mainText="Η σύνδεσή σας στο ίντερνετ αντιμετωπίζει κάποιο πρόβλημα. Ανανεώστε την σελίδα και δοκιμάστε εκ νέου."
            isOpen={openModal}
            modalLabel="Info modal"
            onClickContinue={() => IsNetworkModal.update(false)}
          />
        </Suspense>
      )}
    </>
  );
}

export default sentry.withProfiler(MyApp);
