import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { getAvailableProducts } from '../api/Products';
import { getProductTransformed } from '../utils/products';

import CartStore, {
  productsForOrder,
  ProductsRemovedFromCart,
  ProductsNotRemovedFromCart,
  ValuesToBeUpdated,
} from './cart';
import DeliveryMode from './deliveryMode';
import { IsNetworkModal } from './networkErrorModal';

async function getProdunctInServices(collectionType) {
  const collection = collectionType?.collectionType;
  const { products } = await getAvailableProducts(collectionType.loadProductsURL, {
    collection_eq: collection,
    eligible: true,
  });

  if (isEmpty(products)) {
    return IsNetworkModal.update(true);
  }
  const transformedProducts = products.map(p => getProductTransformed(p));
  const collections = { [collection]: transformedProducts };
  const prevState = ProductsCollection.getValue();
  ProductsCollection.update({ ...prevState, ...collections });
  return { ...prevState, ...collections };
}

const productsReadySubject$ = new BehaviorSubject(false);
const productsReady$ = productsReadySubject$.pipe(
  filter(val => val === true),
  take(1)
);

const ProductsCollection = {
  subject: new BehaviorSubject({}),
  update(collections) {
    ProductsCollection.subject.next(collections);
  },
  removeProductsFromCart: (service, shop) => {
    const productsInCart = productsForOrder.getValue();
    if (!productsInCart?.length) return;
    const collectionTypes = DeliveryMode.getCollectionAsNumber(service, shop);
    const _allProductsIncollections = ProductsCollection.getProductsForCollections(collectionTypes);
    const productsToRemove = productsInCart.filter(
      p => !p.isSpecialOffer && !_allProductsIncollections.some(({ sku }) => sku === p.sku)
    );
    const mainCollectionType = collectionTypes?.find(c => c === process.env.defaultCollectionType || c === '751');
    const offersToRemove = productsInCart.filter(
      o => o.isSpecialOffer && !o.collectionTypes.includes(parseInt(mainCollectionType))
    );
    const productsOffersToRemove = [...productsToRemove, ...offersToRemove];
    ProductsRemovedFromCart.update(productsOffersToRemove);
    const productsNotRemoved = productsInCart.filter(
      product => !productsOffersToRemove.some(p => (!p.isSpecialOffer ? p.sku === product.sku : p._id === product._id))
    );
    const productsReplaced = productsNotRemoved.map(p => {
      if (p?.isSpecialOffer) {
        return p;
      }
      const collectionProduct = _allProductsIncollections.find(({ sku }) => p?.sku === sku);
      const productWithOptions = {
        ...collectionProduct,
        options: p.options,
        userComments: p.userComments,
        url: p.url,
      };
      return productWithOptions;
    });
    if (isEmpty(productsOffersToRemove)) {
      productsForOrder.update(productsReplaced);
      ValuesToBeUpdated.clearValues();
      return;
    }
    ProductsNotRemovedFromCart.update(productsReplaced);
    CartStore.setIsModalOpen(true);
  },

  async onCollectionChangeFetch(service, shop, collectionTypes = [], prevCollectionTypes = []) {
    productsReady$.next(false);
    const _productsCollection = ProductsCollection.getValue();
    await Promise.all(
      collectionTypes.map(async _collection => {
        if (!has(_productsCollection, `${_collection?.collectionType}`)) {
          await getProdunctInServices(_collection);
        }
        return productsReady$.next(true);
      })
    );
    if (!isEqual(collectionTypes, prevCollectionTypes)) {
      ProductsCollection.removeProductsFromCart(service, shop);
    }
  },
  updateCollection(collectionType) {
    getProdunctInServices(collectionType);
  },

  productsReady$,

  subscribe: setState => ProductsCollection.subject.subscribe(setState),
  getValue: () => ProductsCollection.subject.value,
  getProductsForCollections: (collectionTypes = []) => {
    const productCollections = ProductsCollection.getValue();
    const productsInCollections = collectionTypes.reduce((combo, collection) => {
      if (!has(productCollections, collection)) return [];
      return [...productCollections[collection], ...combo];
    }, []);
    return productsInCollections;
  },
};

export default ProductsCollection;
