import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, take, filter } from 'rxjs/operators';
import Cookies from 'universal-cookie';

import { ORDER } from '../api/wave/orders';
import jsonStorage from '../utils/json-storage';

import { IsAddressRegistered, PostalCode } from './isAddressRegistered';

const cookies = new Cookies();

const INITIAL_SUBJECT_VALUE = undefined;

const meta = {
  cached: false,
  fetched: false,
  updatedAt: new Date(),
};

const User = {
  subject: INITIAL_SUBJECT_VALUE,

  /**
   * @private
   * @returns user subject initialized and synced with localStorage
   */
  // eslint-disable-next-line sort-keys
  _lazyInit() {
    if (User.subject) return User.subject;

    const storage = window.localStorage;
    const user = jsonStorage.get('user', { storage });
    Object.assign(meta, {
      cached: true,
      fetched: false,
      updatedAt: new Date(),
    });
    User.subject = new BehaviorSubject(user);
    return User.subject;
  },

  addAddress(address) {
    const currUser = User.getValue();

    const user = {
      ...currUser,
      address: [...currUser.address, address],
    };

    User.update(user);
  },

  getLists() {
    return User.getSubject().pipe(
      map(u => u?.lists || []),
      distinctUntilChanged()
    );
  },

  updateMainAddressInstance() {
    User.getSubject()
      .pipe(
        filter(u => u),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      )
      .subscribe(user => {
        const mainAddress = User.getUserMainAddress();
        User.update({
          ...user,
          mainAddress,
        });
        PostalCode.update(mainAddress?.postalCode);
        IsAddressRegistered.update(true);
      });
  },

  getUserMainAddress() {
    const _user = User.getValue();
    return _user.mainAddress || _user?.address?.[0];
  },

  updateMainAddress(mainAddress) {
    const _user = User.getValue();
    User.update({
      ..._user,
      mainAddress,
    });
  },

  getMainAddress() {
    return User.getSubject().pipe(map(u => u?.mainAddress));
  },

  getSubject() {
    return User._lazyInit();
  },

  getValue() {
    const user = User._lazyInit();
    return user.value;
  },

  reset() {
    cookies.remove('token');
    IsAddressRegistered.update(false);
    PostalCode.update(undefined);
    delete ORDER.defaults.headers.authorization;

    User.getSubject().next(INITIAL_SUBJECT_VALUE);
    User.updateLocalStorage();

    Object.assign(meta, {
      cached: false,
      fetched: false,
      updatedAt: new Date(),
    });
  },

  setLists(lists) {
    const prevState = User.getValue();
    return User.update({
      ...prevState,
      lists,
    });
  },

  subscribe(setState) {
    const user = User._lazyInit();
    return user.subscribe(setState);
  },

  update(newValues) {
    const user$ = User.getSubject();
    const prevState = user$.value;
    const newState = {
      ...prevState,
      ...newValues,
    };
    user$.next(newState);

    Object.assign(meta, {
      fetched: true,
      updatedAt: new Date(),
    });

    User.updateLocalStorage();
  },

  updateLocalStorage(newState = User.getValue()) {
    const storage = window.localStorage;
    jsonStorage.set('user', newState, { storage });
  },

  isEligibleToRate$() {
    return User._lazyInit().pipe(
      take(1),
      map((user = {}) => {
        const lastRatingAt = user?.lastRatings?.web?.createdAt;

        return !lastRatingAt || Math.abs(dayjs(lastRatingAt).diff(dayjs(), 'months')) >= 3;
      })
    );
  },
};

export default User;
