import { BehaviorSubject } from 'rxjs';

import jsonStorage from '../utils/json-storage';

const addressCoordinates = new BehaviorSubject({});
const isModalSubject = new BehaviorSubject(false);

export const IsAddressRegistered = {
  subject: undefined,

  lazyInit() {
    if (IsAddressRegistered.subject) return IsAddressRegistered.subject;

    const storage = window.localStorage;
    const isRegistered = jsonStorage.get('IsAddressRegistered', { storage });

    IsAddressRegistered.subject = new BehaviorSubject(isRegistered);
    return IsAddressRegistered.subject;
  },

  update: mode => {
    const isRegistered = IsAddressRegistered.lazyInit();
    isRegistered.next(mode);
    const storage = window.localStorage;
    jsonStorage.set('IsAddressRegistered', mode, { storage });
  },

  delete: () => {
    const storage = window.localStorage;
    jsonStorage.remove('IsAddressRegistered', { storage });
  },

  subscribe: setState => {
    const isRegistered = IsAddressRegistered.lazyInit();
    return isRegistered.subscribe(setState);
  },
};

export const PostalCode = {
  subject: undefined,

  lazyInit() {
    if (PostalCode.subject) return PostalCode.subject;

    const storage = window.localStorage;
    const _postalCode = jsonStorage.get('PostalCode', { storage });
    const postalCode = !_postalCode || ['undefined', 'null', ''].includes(_postalCode) ? undefined : _postalCode;

    PostalCode.subject = new BehaviorSubject(postalCode);
    return PostalCode.subject;
  },

  update: _postal => {
    const postalCode = PostalCode.lazyInit();
    const postal = parseInt(_postal);
    postalCode.next(postal);
    const storage = window.localStorage;
    jsonStorage.set('PostalCode', postal, { storage });
  },
  delete: () => {
    const storage = window.localStorage;
    jsonStorage.remove('PostalCode', { storage });
  },
  subscribe: setState => {
    const postalCode = PostalCode.lazyInit();
    return postalCode.subscribe(setState);
  },
  getValue: () => {
    const postalCode = PostalCode.lazyInit();
    return postalCode.value;
  },
};

export const AddressCoordinates = {
  update: coordinates => {
    addressCoordinates.next(coordinates);
  },
  subscribe: setState => addressCoordinates.subscribe(setState),
};

export const IsCheckAddressModal = {
  update: value => {
    isModalSubject.next(value);
  },
  subscribe: setState => isModalSubject.subscribe(setState),
};

export default {
  IsAddressRegistered,
  PostalCode,
  AddressCoordinates,
  IsCheckAddressModal,
};
