import { BehaviorSubject } from 'rxjs';

const subjectConfigsMYO = new BehaviorSubject([]);

const ConfigsMYOStore = {
  update: configsMYO => {
    subjectConfigsMYO.next(configsMYO);
  },
  subscribe: setState => subjectConfigsMYO.subscribe(setState),
  getValue: () => subjectConfigsMYO.value,
};

export default ConfigsMYOStore;
