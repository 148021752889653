import { BehaviorSubject } from 'rxjs';

const subjectCampaigns = new BehaviorSubject([]);

const CampaignStore = {
  update: campaigns => {
    subjectCampaigns.next(campaigns);
  },
  subscribe: setState => subjectCampaigns.subscribe(setState),
  getValue: () => subjectCampaigns.value,
};

export default CampaignStore;
