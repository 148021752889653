import { BehaviorSubject } from 'rxjs';

const INITIAL_VALUE = {
  events: [],
  isVisible: false,
};

/**
 * Events are identifiers that some components wait in order to render properly.
 * As long as the events list is not empty the loader will be visible,
 * except it will be requested to force set visibility using Loader.setIsVisible.
 *
 * Prefer to use Loader store by adding and removing events.
 *
 * For example:
 * 1. add event
 * 2. fetch data
 * 3. remove event
 */
const Loader = {
  subject: new BehaviorSubject(INITIAL_VALUE),

  addEvent(identifier) {
    console.debug('Loader', 'addEvent', identifier);
    const prevState = Loader.subject.getValue();
    const prevEvents = prevState.events || [];
    const nextEvents = [...prevEvents, identifier];
    Loader.updateEvents(nextEvents);
  },

  removeEvent(identifier) {
    console.debug('Loader', 'removeEvent', identifier);
    const prevState = Loader.subject.getValue();
    const prevEvents = prevState.events || [];
    const nextEvents = prevEvents.filter(e => e !== identifier);
    Loader.updateEvents(nextEvents);
  },

  update(value) {
    console.debug('Loader', 'update', value);
    Loader.subject.next(value);
  },

  updateEvents(events) {
    const isVisible = Boolean(events.length);
    const userMessage = '';
    Loader.update({
      events,
      isVisible,
      userMessage,
    });
  },

  setIsVisible(isVisible) {
    Loader.update({
      events: [],
      isVisible,
    });
  },

  getValue: () => Loader.subject.value,
  subscribe: onUpdate => Loader.subject.subscribe(onUpdate),
};

export default Loader;
